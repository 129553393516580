import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

const Hero = ({ hero }) => {
  //console.log("Hero component hero:", hero)
  //console.log("Hero component hero buttons:", hero.buttons)
  //console.log("Hero component image:", hero.Image)

  return (
    <section id="home">
      <div className="uk-cover-container uk- uk-margin-remove-bottom">
        <GatsbyImage
          image={getImage(hero?.image?.localFile)}
          alt="Adu dev hero image"
          style={{ height: "650px" }}
        />

        <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
          <div className="uk-flex uk-flex-center">
            <div className="uk-width-3-3 uk-margin-top">
              <div className="uk-heading-medium uk-text-center uk-light uk-animation-fade uk-animation-slide-top-medium uk-margin-medium-top">
                <StaticImage
                  src="../images/stsp-banner-03.png"
                  alt="save the street pooches banner orange to grey graident"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  title="save the street pooches banner orange to grey graident"
                  style={{ maxWidth: "280px" }}
                />
              </div>

              {hero.logo ? (
                <>
                  <div className=" uk-text-center uk-animation-fade uk-animation-slide-right-medium uk-margin-medium-bottom uk-visible@m">
                    <GatsbyImage
                      image={getImage(hero?.logo?.localFile)}
                       alt="STSP logo"
                      //height="250"
                      //style={{ height: "150px" }}
                      //style={{ width: "160px" }}
                    />
                  </div>
                  <div className=" uk-text-center uk-animation-fade uk-animation-slide-right-medium uk-margin-medium-bottom uk-hidden@m">
                    <GatsbyImage
                      image={getImage(hero?.logo?.localFile)}
                      alt="STSP logo"
                      //height="250"
                      //style={{ height: "150px" }}
                      //style={{ width: "120px" }}
                    />
                  </div>
                </>
              ) : (
                null
              )}

              <div>
                <h3 className="uk-animation-fade uk-animation-slide-left-medium uk-visible@s">
                  {hero.callToAction}
                </h3>
              </div>
              

              <div className="heroText">
                 <h4 className="uk-animation-fade uk-animation-slide-left-medium uk-hidden@s">
                {hero.callToAction}
              </h4>

              {/* <div>
                <h3 className="uk-animation-fade uk-animation-slide-left-medium uk-visible@s">
                  <span>{hero.callToAction}</span>
                </h3>
              </div>
              

              <div className="heroText">
                 <h4 className="uk-animation-fade uk-animation-slide-left-medium uk-hidden@s">
                 <span className="green-grad">{hero.callToAction}</span>
              </h4> */}
              </div>
             
              <div className="uk-flex uk-flex-center uk-flex-middle uk-margin">
                <div className="uk-margin-bottom">
                  {hero?.Buttons?.map(button => {
                    //console.log("Button:", button)
                    return (
                      <Link
                        key={button.strapi_id}
                        to={button.link}
                        title={button.name}
                        alt={button.name}
                        toggle="button"
                        className="uk-button uk-button-hero uk-button-small uk-text-small uk-animation-fade uk-animation-slide-bottom uk-margin-small-right uk-margin-bottom uk-margin-top"
                        style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px"}}
                      >
                        <span>{button.name}</span>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Hero
