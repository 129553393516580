import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import { Helmet } from "react-helmet"

const FaqsPage = () => {
  const { strapiFaq } = useStaticQuery(graphql`
    query {
      strapiFaq {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
          structuredData {             
            mainEntity {
              _type
              acceptedAnswer {
                _type
                text
              }
            }
          }
        }
        headings {
          title
          description
        }
      }
    }
  `)

  //console.log("strapiFaq: ", strapiFaq)

  const { hero, headings, seo } = strapiFaq

  const structuredData = seo.structuredData

  const imageUrl = `${seo.metaImage.localFile.url}`

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            structuredData,
          })}
        </script>
      </Helmet>
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={imageUrl}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      <Hero hero={hero} />

      <section className="uk-section-default">
        <Headings title={headings.title} description={headings.description} />

        <div className="uk-container uk-margin-medium-bottom">
          <div uk-grid="true" className="uk-grid-match uk-child-width-1-2@s">
            <div className="pooche-card">
              <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                <div
                  className="uk-card-badge uk-label"
                  style={{ backgroundColor: "#ff7f00", fontWeight: "700" }}
                >
                  FAQ
                </div>
                <h3 className="uk-card-title green-gradient-text-title uk-margin-remove-bottom uk-margin-small-top">
                  How to make a donation?
                </h3>
                <p>
                  The donations we receive for these street dogs are the
                  backbone of our work and vital for their on going care and in
                  most cases life saving. Read more, to see how you can make a
                  donation today.
                </p>
                {/* This is an anchor toggling the modal */}

                <button
                  className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade uk-margin-small-right buttonHover"
                  style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                  uk-toggle="target: #modal-donations"
                >
                  Read More
                </button>

                {/* This is the modal */}
                <div
                  id="modal-donations"
                  className="uk-modal-container"
                  uk-modal="true"
                >
                  <div className="uk-modal-dialog">
                    <button
                      className="uk-modal-close-default"
                      type="button"
                      aria-label="Close"
                      uk-close="true"
                    ></button>

                    <div className="uk-modal-header">
                      <h3
                        style={{ color: "#617F7F", fontWeight: "700" }}
                        className=""
                      >
                        How to make a donation
                      </h3>
                    </div>

                    <div className="uk-modal-body" uk-overflow-auto="true">
                      <h4 className="">General donations</h4>
                      <p>General Donations FAQs coming soon!</p>
                      <h4 className="">Donations for specific dogs</h4>
                      <p>Donations for specific dogs FAQs coming soon!</p>
                      <h4 className="">How to make payments</h4>
                      <p>How to make payments FAQs coming soon!</p>
                    </div>

                    <div className="uk-modal-footer uk-text-right">
                      <button
                        className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade buttonHover uk-modal-close"
                        style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                        //uk-toggle="target: #modal-donations"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of modal */}
              </div>
            </div>
            <div className="pooche-card">
              <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                <div
                  className="uk-card-badge uk-label"
                  style={{ backgroundColor: "#ff7f00", fontWeight: "700" }}
                >
                  FAQ
                </div>
                <h3 className="uk-card-title green-gradient-text-title uk-margin-remove-bottom uk-margin-small-top">
                  How to sponsor a dog?
                </h3>
                <p>
                  The sponsorships we receive for these street dogs help us to
                  provide continued lifesaving support, giving them a better
                  life. Read more, to see how you can sponsor a street dog
                  today.
                </p>
                {/* This is an anchor toggling the modal */}
                <button
                  className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade uk-margin-small-right buttonHover"
                  style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                  uk-toggle="target: #modal-sponsorship"
                >
                  Read More
                </button>

                {/* This is the modal */}
                <div
                  id="modal-sponsorship"
                  className="uk-modal-container"
                  uk-modal="true"
                >
                  <div className="uk-modal-dialog">
                    <button
                      className="uk-modal-close-default"
                      type="button"
                      aria-label="Close"
                      uk-close="true"
                    ></button>

                    <div className="uk-modal-header">
                      <h3
                        style={{ color: "#617F7F", fontWeight: "700" }}
                        className=""
                      >
                        How to sponsor a dog?
                      </h3>
                    </div>

                    <div className="uk-modal-body" uk-overflow-auto="true">
                      <h4 className="">Sponsorship for rescue dogs</h4>
                      <p>Sponsorship for rescue dogs FAQs coming soon!</p>
                      <h4 className="">Sponsorship for shelter dogs</h4>
                      <p>Sponsorship for shelter dogs FAQs coming soon!</p>
                      <h4 className="">Sponsorship for disabled dogs</h4>
                      <p>Sponsorship for disabled dogs FAQs coming soon!</p>
                      <h4 className="">Sponsorship for newborns & puppies</h4>
                      <p>
                        Sponsorship for newborns & puppies FAQs coming soon!
                      </p>
                      <h4 className="">How to make payments</h4>
                      <p>How to make payments FAQs coming soon!</p>
                    </div>

                    <div className="uk-modal-footer uk-text-right">
                      <button
                        className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade buttonHover uk-modal-close"
                        style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                        //uk-toggle="target: #modal-sponsorship"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of modal */}
              </div>
            </div>
            <div className="pooche-card">
              <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                <div
                  className="uk-card-badge uk-label"
                  style={{ backgroundColor: "#ff7f00", fontWeight: "700" }}
                >
                  FAQ
                </div>
                <h3 className="uk-card-title green-gradient-text-title uk-margin-remove-bottom uk-margin-small-top">
                  How to get involved in fundraising?
                </h3>
                <p>
                  Fundraising enables us to reach more people and run a better
                  operation. We work on effective strategies to meet our
                  community commitments. Read more, to get involved today.
                </p>
                {/* This is an anchor toggling the modal */}
                <button
                  className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade uk-margin-small-right buttonHover"
                  style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                  uk-toggle="target: #modal-fundraising"
                >
                  Read More
                </button>
                {/* This is the modal */}
                <div
                  id="modal-fundraising"
                  className="uk-modal-container"
                  uk-modal="true"
                >
                  <div className="uk-modal-dialog">
                    <button
                      className="uk-modal-close-default"
                      type="button"
                      aria-label="Close"
                      uk-close="true"
                    ></button>

                    <div className="uk-modal-header">
                      <h3
                        style={{ color: "#617F7F", fontWeight: "700" }}
                        className=""
                      >
                        How to get involved in fundraising?
                      </h3>
                    </div>

                    <div className="uk-modal-body" uk-overflow-auto="true">
                      <h4 className="">Fundraising Events</h4>
                      <p>Fundraising events FAQs coming soon!</p>
                      <h4 className="">Fundraising Stalls</h4>
                      <p>Fundraising stalls FAQs coming soon!</p>
                      <h4 className="">Fundraising Volunteers</h4>
                      <p>Fundraising volunteers FAQs coming soon!</p>
                    </div>

                    <div className="uk-modal-footer uk-text-right">
                      <button
                        className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade buttonHover  uk-modal-close"
                        style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                        //uk-toggle="target: #modal-fundraising"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of modal */}
              </div>
            </div>
            <div className="pooche-card">
              <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                <div
                  className="uk-card-badge uk-label"
                  style={{ backgroundColor: "#ff7f00", fontWeight: "700" }}
                >
                  FAQ
                </div>
                <h3 className="uk-card-title green-gradient-text-title uk-margin-remove-bottom uk-margin-small-top">
                  How to volunteer?
                </h3>
                <p>
                  We would not be able to continue our important work at Save
                  the Street Pooches without the continued devotion and support
                  of our tireless volunteers. Read more, to get involved today.
                </p>
                {/* This is an anchor toggling the modal */}

                <button
                  className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade uk-margin-small-right buttonHover"
                  style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                  uk-toggle="target: #modal-volunteer"
                >
                  Read More
                </button>

                {/* This is the modal */}
                <div
                  id="modal-volunteer"
                  className="uk-modal-container"
                  uk-modal="true"
                >
                  <div className="uk-modal-dialog">
                    <button
                      className="uk-modal-close-default"
                      type="button"
                      aria-label="Close"
                      uk-close="true"
                    ></button>

                    <div className="uk-modal-header">
                      <h3
                        style={{ color: "#617F7F", fontWeight: "700" }}
                        className=""
                      >
                        How to volunteer?
                      </h3>
                    </div>

                    <div className="uk-modal-body" uk-overflow-auto="true">
                      <h4 className="">Fundraising Volunteers</h4>
                      <p>Fundraising volunteers FAQs coming soon!</p>
                      <h4 className="">Street Feeding Volunteers</h4>
                      <p>Street feeding FAQs coming soon!</p>
                      <h4 className="">Shelter Volunteers</h4>
                      <p>Shelter volunteers FAQs coming soon!</p>
                      <h4 className="">Community Volunteers</h4>
                      <p>Community volunteers FAQs coming soon!</p>
                    </div>

                    <div className="uk-modal-footer uk-text-right">
                      <button
                        className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade buttonHover uk-modal-close"
                        style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                        //uk-toggle="target: #modal-volunteer"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of modal */}
              </div>
            </div>
            <div className="pooche-card">
              <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                <div
                  className="uk-card-badge uk-label"
                  style={{ backgroundColor: "#ff7f00", fontWeight: "700" }}
                >
                  FAQ
                </div>
                <h3 className="uk-card-title green-gradient-text-title">
                  How to use our site?
                </h3>
                <p>
                  Welcome to Save the Street Poches website, you have reached
                  the one stop shop for all you questions and answers. Read more
                  to see how to best use our site, and find the information you
                  are looking for.
                </p>
                {/* This is an anchor toggling the modal */}

                <button
                  className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade uk-margin-small-right buttonHover"
                  style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                  uk-toggle="target: #modal-site"
                >
                  Read More
                </button>

                {/* This is the modal */}
                <div
                  id="modal-site"
                  className="uk-modal-container"
                  uk-modal="true"
                >
                  <div className="uk-modal-dialog">
                    <button
                      className="uk-modal-close-default"
                      type="button"
                      aria-label="Close"
                      uk-close="true"
                    ></button>

                    <div className="uk-modal-header">
                      <h3
                        style={{ color: "#617F7F", fontWeight: "700" }}
                        className=""
                      >
                        How to use our site?
                      </h3>
                    </div>

                    <div className="uk-modal-body" uk-overflow-auto="true">
                      <h4 className="">Site map</h4>
                      <p>Site map coming soon!</p>
                      <h4 className="">Articles and Categories</h4>
                      <p>Articles and categories FAQs coming soon!</p>
                      <h4 className="">Contacting Us</h4>
                      <p>Contact us FAQs coming soon!</p>
                      <h4 className="">Our Social Media Platforms</h4>
                      <p>
                        Connecting with us on our Social Media Platforms FAQs
                        coming soon!
                      </p>
                    </div>

                    <div className="uk-modal-footer uk-text-right">
                      <button
                        className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade buttonHover uk-modal-close"
                        style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                        //uk-toggle="target: #modal-site"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of modal */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FaqsPage
